
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Exception404'
})
export default class Exception404 extends Vue {
  private toBack() {
    this.$router.back()
  }
}
